import React, { useState } from 'react';
import MedidataMetrics from '../MedidataMetrics';

const QualityMetricsForCenters = () => {
  const [currentTab, setCurrentTab] = useState('medidatametrices')
  const tagsData = [
    {
      additionalClass: '',
      key: 'medidatametrices',
      name: 'Medidata Metrices',
      title: '',
    },
    // {
    //   additionalClass: '',
    //   key: 'purplelab',
    //   name: 'Purple lab',
    //   title: '',
    // },
    // {
    //   additionalClass: '',
    //   key: 'studymatrix',
    //   name: 'Study Matrix',
    //   title: '',
    // },
  ]

  const renderActiveContent = () => {
    switch (currentTab) {
      case 'medidatametrices': {
        return (
          <div>
            <MedidataMetrics />
          </div>
        )
      }
      case 'purplelab': {
        return (
          <div />
        )
      }
      case 'studymatrix': {
        return (
          <div />
        )
      }
      // case 'compliance': {
      //   return (
      //     <div>
      //       {/* <GbdForesight /> */}
      //     </div>
      //   )
      // }
      default: return null
    }
  }
  return (
    <>
      <div className="details-page-tabs country-project-tabs-list-tab">
        <div className="searbar-tabs center-deep-dive">
          {tagsData.map((tabItm, index) => (
            <div
              className={`tagsbutton ${currentTab === tabItm.key ? 'active' : ''} ${tabItm.additionalClass}`}
              role="presentation"
              onClick={() => {
                if (currentTab !== tabItm.key && tabItm.additionalClass.indexOf('disabled') === -1) {
                  setCurrentTab(tabItm.key);
                }
              }}
              title={tabItm.title}
              key={index}
            >
              <div className="tab-title">
                {tabItm.name}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div>
        {renderActiveContent()}
      </div>
    </>
  )
}

export default QualityMetricsForCenters
